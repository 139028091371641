.App {
  text-align: center;
  padding: 20px;
  background-color: #282c34;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#construction-contact {
  font-family: monospace;
}

a {
  color: #FF2171;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.loading {
  font-weight: bold;
  display:inline-block;
  font-family: monospace;
  /*font-size: 30px;*/
  clip-path: inset(0 3ch 0 0);
  animation: l 1.5s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.grid-image {
  width: 100%;
  height: 80%;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1200px) {
  .image-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .image-grid {
    grid-template-columns: 1fr;
  }
}

.page-header {
  padding: 40px 20px;
  margin-bottom: 20px;
}

.page-header h1 {
  color: white;
  font-size: 3rem;
  margin: 0 0 40px 0;
  font-family: monospace;
}

.facebook-link {
  color: #eea937;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 10px 20px;
  border: 2px solid #eea937;
  border-radius: 25px;
  transition: all 0.3s ease;
  font-family: monospace;
  display: inline-block;
  width: 40%;
}

.facebook-link:hover {
  background-color: #eea937;
  color: black;
  text-decoration: none;
}

#main_icon {
  width: 200px;
}
